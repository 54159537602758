body {
  margin: 3;
  background-color: var(--surface-b);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'primereact/resources/themes/saga-purple/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

.layout-wrapper {
  margin: 1%;
}

.side-bar-button {
  background-color: #fff;
  bottom: 0;
  box-shadow: -1px 0 6px rgb(0 0 0 / 20%);
  cursor: pointer;
  height: 72px;
  outline: none;
  overflow: hidden;
  top: 0;
  visibility: inherit;
  width: 36px;
  z-index: 1;
  -webkit-user-select: none;
  display: inline-flex;
  position: fixed;
  border-radius: 0 36px 36px 0;
  left: 0;
  margin: auto;
}

.side-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.side-bar-header-title {
  font-size: 1.2em;
}
.side-bar-header-buttons {
  display: inline-flex;
}

.requestTableContainer {
  & :global(th[role='columnheader']) {
    position: sticky;
    top: 0;
  }
}

.pin-menu-btn:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.pin-menu-div {
  flex: 0 0 300px;
  width: 300px;
  display: inline-block;
  position: sticky;
  top: 0px;
  vertical-align: top;
  padding-right: 10px;
}

.unpin-btn {
  float: right;
}

.container-with-pin {
  display: flex;
}

// public style
.card-balance-number {
  font-size: 1.5rem;
  margin: 0px;
  font-weight: 700;
  line-height: 1.5;
}

.card-balance-description {
  margin: 0px;
  font-weight: 600;
  line-height: 1.57143;
  font-size: 0.875rem;
  opacity: 0.72;
}

.card-total {
  color: rgb(0, 82, 73);
  background-color: rgb(200, 250, 205);
}

.card-deposite {
  color: rgb(4, 41, 122);
  background-color: rgb(208, 242, 255);
}

.card-withdraw {
  color: rgb(122, 12, 46);
  background-color: rgb(255, 231, 217);
}

.card-balance {
  color: rgb(122, 79, 1);
  background-color: rgb(255, 247, 205);
}

.card-content {
  text-align: center;
}
